import React from 'react'
import { Helmet } from "react-helmet";

const NotFoundPage = () => (
  <>
    <Helmet title={'Page Not Found'}>
    </Helmet>
    <div className="container--l cb mxa p1 outer pyl error">
      <h1 className="">Oops!</h1>
      <h3>Sorry, the page you're looking for does not exist!</h3>
    </div>
  </>
)

export default NotFoundPage
